import React from 'react';
import { AppBar, Button, FormControlLabel, Icon, makeStyles, Switch } from '@material-ui/core'
import { Switch as RouteSwitch, Route, Redirect, useHistory, useLocation } from 'react-router-dom'
import PdfViewer from './PdfViewer'
import Terms from './Terms'

const useStyles = makeStyles(() => ({
  app: {
    height: '100vh'
  },
  logo: {
    margin: 'auto 0'
  },
  appContactGroup: {
    height: '40px',
    margin: '20px 0',
    display: 'flex',
    '& a': {
      color: 'white',
      padding: '0 8px',
      textDecoration: 'none'
    }
  },
  appContact: {
    display: 'flex'
  },
  appHeader: {
    height: '100px',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    background: 'linear-gradient(to right,#57ad75 0%,#b8df67 100%)'
  },
  appNavigation: {
    height: '56px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    margin: 'auto 0'
  },
  navButton: {
    color: 'white'
  },
  appBody: {
    height: 'calc(100% - 100px)'
  },
  relSwitchContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    flexDirection: 'column'
  },
  relSwitchThumb: {
    color: '#57ad75 !important'
  },
  relSwitchTrack: {
    background: 'linear-gradient(to right,#57ad75 0%,#b8df67 100%)'
  }
}))

const urlify = platform => `/${platform}`

const platforms = [
    {
        key: 'relativity',
        name: 'Relativity',
        overrides : {
            relativity: 'RelativityOne',
            relativityone: 'RelativityOne',
            relativity2: 'Relativity'
        },
        render: 'switch',
        renderInfo: {
            label: 'See RelativityOne?',
            test: docKey => docKey === 'RelativityOne',
            histUrl: docKey => urlify(docKey === 'RelativityOne' ? 'Relativity2' : 'RelativityOne')
        }
    },
    {
        key: 'entity',
        name: 'Entity',
        overrides: {
            entity: 'Entity',
            entitycm: 'EntityCM',
            'entity-case-management': 'EntityCM'
        },
        render: 'switch',
        renderInfo: {
            label: 'See Entity Case Management?',
            test: docKey => docKey === 'EntityCM',
            histUrl: docKey => urlify(docKey === 'EntityCM' ? 'Entity' : 'EntityCM')
        }
    },
    {
        key: 'brainspace',
        name: 'Brainspace',
        overrides: {},
        render: 'pdf'
    },
    {
        key: 'nuix',
        name: 'Nuix',
        overrides: {},
        render: 'pdf'
    },
    {
        key: 'terms',
        name: 'Terms',
        overrides: {},
        render: 'terms'
    }
]
const getDocumentUrl = platform => {
  const url = `https://docs.anexsys.net/gcloud?key=${platform}`
  //console.log('getDocumentUrl:', url)
  return url
}

function App() {
  const history = useHistory()
  const location = useLocation()
  const classes = useStyles()
  const currPath = location.pathname.toLowerCase().slice(1)
  return (
    <div className={classes.app}>
      <AppBar position='static' className={classes.appHeader} role='banner'>
        <img className={classes.logo} width={165} height={41} alt='Anexsys Logo' src='https://anexsys.com/wp-content/uploads/2019/04/logo-white-400x99.png'/>
        <nav className={classes.appNavigation} aria-label='Platform Navigation' role='navigation'>
          {
            platforms.map(p => {
              const selectedProps = (p.key === currPath) || !!(p.overrides[currPath])
                ? { disabled: true, 'aria-current': 'page' }
                : {}
              return (
                <Button
                  className={classes.navButton}
                  key={p.key}
                  onClick={() => history.push(urlify(p.name))}
                  {...selectedProps}
                >
                  {p.name}
                </Button>
              )
            })
          }
        </nav>
        <div className={classes.appContactGroup}>
          <a href='tel:+44 (0) 203 217 0300' aria-label='Call Anexsys'>
            <div className={classes.appContact}>
              <Icon>local_phone</Icon>
              <span>+44 (0) 203 217 0300</span>
            </div>
          </a>
          <a href='mailto:hello@anexsys.com' aria-label='Email Anexsys'>
            <div className={classes.appContact}>
              <Icon>email</Icon>
              <span>hello@anexsys.com</span>
            </div>
          </a>
        </div>
      </AppBar>
      <main className={classes.appBody} role='main'>
        <RouteSwitch>
          <Route
            exact
            path='/'
            render={() => <Redirect to={urlify(platforms[0].name)} />}
          />
          <Route
            path='*'
            render={() => {
              // find a matching platform
              const p = platforms.find(p => !!(p.overrides[currPath]) || (p.key === currPath));              
              if (p) {
                let docKey;
                switch (p.render) {
                  case 'switch':
                    docKey = p.overrides[currPath] || p.name;
                    //console.log(docKey, p.key);
                    return (<>
                      <div className={classes.relSwitchContainer}>
                        <FormControlLabel
                          label={p.renderInfo.label}
                          control={<Switch
                            checked={p.renderInfo.test(docKey)}
                            onChange={() => history.push(p.renderInfo.histUrl(docKey))}
                            classes={{ checked: classes.relSwitchThumb, track: classes.relSwitchTrack }}
                          />}
                        />
                        <PdfViewer key={currPath} document={getDocumentUrl(docKey)} />
                      </div>
                    </>)
                  case 'pdf':
                    docKey = p.overrides[currPath] || p.name;
                    return <PdfViewer key={currPath} document={getDocumentUrl(docKey)} />
                  case 'terms':
                  default:
                    return <Terms />
                }
              }
              // fallback
              return <Redirect to={urlify(platforms[0].name)} />
            }}
          />
        </RouteSwitch>
      </main>
    </div>
  );
}

export default App;
